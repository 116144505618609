@import "~styles/variables";
@import "~styles/mixins";

.modal-add-user {
  [aria-labelledby="customized-add-user"] {
    width: 726px;
    max-width: 726px;
    border-radius: 8px;
    // box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  }

  #customized-add-user {
    display: flex;
    align-items: center;
    background: $header;
    border-radius: 8px 8px 0px 0px;

    @include modal-title();

    span {
      color: #1a9696;
      font-weight: 400;
      font-size: 12px;
      margin-left: 15px;
    }

    .MuiSvgIcon-root {
      fill: #000;
    }

    .MuiIconButton-root {
      top: 20px;
      right: 35px;
    }
  }

  .MuiDialogTitle-root {
    padding: 0 45px;
  }

  &_body {
    height: 582px;
    padding: 42px 60px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_form {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;

      &_control-label {
        margin-left: 0px;
        margin-right: 0px;
        flex-direction: column-reverse;
        align-items: flex-start;
        width: calc(50% - 15px);

        .MuiFormControlLabel-label {
          font-family: $fontSecond;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;

          color: $brandSecondaryDark;

          &::after {
            content: "*";
            color: red;
          }
        }

        .MuiTextField-root,
        .MuiInputBase-root {
          width: 100%;
          height: 48px;

          .MuiInputBase-input {
            padding: 12.5px 14px;
          }

          .MuiOutlinedInput-root {
            &.Mui-focused fieldset {
              border-width: 1px;
            }
          }
        }
      }

      &_checkbox {
        .Mui-checked {
          color: $brandPrimaryColor;
        }

        .MuiFormControlLabel-label {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;

          color: #505050;
        }
      }

      &_wrap-btns {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        column-gap: 20px;
        margin-top: 20px;
      }

      &_success-text {
        background: rgba(42, 212, 172, 0.24);
        border: 1px solid $green-light;
        box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
        border-radius: 5px;

        font-family: $fontPrimary;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;

        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        padding: 6px 115px;

        color: #2f2c2c;
      }

      &_error-text {
        background: rgba(255, 141, 148, 0.16);
        border: 1px solid #f65761;
        box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
        border-radius: 5px;

        font-family: $fontPrimary;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;

        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        padding: 6px 115px;

        color: #2f2c2c;
      }
    }

    .form-access-level {
      &_btn-group {
        width: 100%;
        margin-bottom: 20px;

        &_btn {
          font-family: $fontSecond;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          height: 40px;
          width: 25%;
          background: #55bbbe;
          border: 1px solid #dadedf;
          border-radius: 8px;
          text-transform: none;
          color: $white;

          &:hover {
            background: #1a9696;
          }

          &:not(:last-of-type) {
            border-right: 3px solid #dadedf;
          }
        }

        &_btn-active {
          background: #1a9696;
        }
      }

      .MuiPaper-root:nth-child(2n) {
        margin-bottom: 40px;
      }

      .MuiPaper-root:nth-child(2n + 1) {
        margin-bottom: 23px;
      }
    }
  }
}
