.rs-picker {
  .rs-picker-default .rs-picker-toggle.rs-btn-lg {
    padding-bottom: 15px;
  }
  svg {
    margin-top: 3px;
    width: 20px;
    color: #1a9696;
  }
  .rs-picker-toggle {
    height: 48px;
  }
  .rs-stack {
    .rs-stack-item {
      .rs-picker-toggle-placeholder {
        padding-top: 13px !important;
        font-size: 16px;
      }
      span {
        padding: 0;
      }
    }
  }

  .rs-picker-toggle-placeholder {
    margin-top: 10px !important;
    padding: 0;
  }
}

.rs-picker-daterange-panel {
  .rs-picker-toolbar {
    .rs-stack-item {
      .rs-picker-toolbar-ranges {
        .rs-btn-sm {
          display: none;
        }
      }
    }
  }
  .rs-picker-daterange-content {
    .rs-picker-daterange-header {
      display: none;
    }
  }
}
