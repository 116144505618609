.layout {
  display: flex !important;
  flex-direction: column;
  max-width: none;
  padding: 0;
  overflow-x: hidden;

  .layout-wrap {
    display: flex;
    min-height: calc(100vh - 92px);

    .layout-menu {
      background: #1a9696;
    }

    .layout-main {
      padding: 62px 72px 70px 57px;
      flex-grow: 1;
      background: #f4f7fc;
      width: calc(100% - 348px);
    }
  }
}
