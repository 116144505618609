@import "~styles/variables";

.pipe-information {
  &_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $fontSecond;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;

    color: #2f2c2c;
  }

  &_body {
    display: flex;
    flex-direction: column;
    row-gap: 30px;

    margin-top: 40px;

    &_accordion {
      background: #ffffff;
      box-shadow: 0px 5px 16px rgb(8 15 52 / 6%);
      border-radius: 18px;

      &:before {
        height: 0;
        background-color: unset;
      }

      &:first-of-type {
        border-top-left-radius: 18px;
        border-top-right-radius: 18px;
      }

      &:last-of-type {
        border-bottom-left-radius: 18px;
        border-bottom-right-radius: 18px;
      }

      &_summary {
        min-height: 130px;

        .MuiAccordionSummary-content.Mui-expanded {
          min-height: 24px;
        }

        .MuiAccordionSummary-expandIconWrapper {
          background: $white;
          box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
            0px 1px 10px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
          border-radius: 25px;
        }

        .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
          transform: rotate(90deg);
          background: #1a9696;
          box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
            0px 1px 10px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
          border-radius: 25px;

          .MuiSvgIcon-root {
            color: $white;
          }
        }

        &_title {
          font-family: $fontSecond;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 28px;

          color: #2f2c2c;
        }
      }

      &_summary.Mui-expanded {
        min-height: 95px;
      }
    }
  }

  .MuiDataGrid-cell {
    &:first-child {
      display: flex;
      justify-content: center;
      background: rgba(244, 247, 252, 0.5);
    }
  }
}

.grade-management_head,
.bend-management_head,
.diameter-management_head {
  margin-bottom: 43px;
}
