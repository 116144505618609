@import "~styles/variables";

.products-management {
  &_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $substrate;
    font-family: $fontSecond;
    font-weight: 700;
    font-size: 24px;
    color: #2f2c2c;

    box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
      0px 1px 4px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 35px 40px;
  }

  &_buttons {
    display: flex;
    align-items: center;
    column-gap: 20px;

    .MuiButton-root {
      margin-left: 40px;
    }

    .copy-btn,
    .delete-btn {
      border: 1px solid #3b61a5;
      border-radius: 8px;
      height: 32px;

      &:hover {
        border: 2px solid #55bbbe;
        background: #e7ecf5;
      }
    }
  }

  &_subtitle {
    background: #e7ecf5;
    height: 58px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    padding: 16px 0 10px 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid rgba(224, 224, 224, 1);
    border-bottom: none;
  }

  .MuiDataGrid-toolbarContainer {
    background: #e7ecf5;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  .MuiDataGrid-root {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .MuiDataGrid-columnHeader {
    &:first-child {
      padding: 0;

      .MuiDataGrid-columnHeaderTitleContainer {
        display: flex;
        justify-content: center;

        .MuiDataGrid-columnHeaderTitle {
          color: $black;
        }
      }
    }
  }
}
