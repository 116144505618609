@import "~styles/variables";
@import "~styles/mixins";

.form {
  display: flex;
  justify-content: space-between;
  width: 74%;

  .css-1as232s-MuiStack-root {
    width: 100%;
  }
  span {
    color: #2f2c2c;
    padding-bottom: 8px;
    font-size: 14px;
  }
  .css-1as232s-MuiStack-root {
    padding: 0;
  }
  .user__form-control {
    margin-left: 70px;
    margin-right: -2px;
    flex-direction: column-reverse;
    align-items: flex-start;
    width: 50%;

    .MuiInputBase-root {
      background: #ffffff;
      text-overflow: ellipsis;
      height: 48px;
    }
  }
}

.general-statistic {
  display: flex;
  flex-direction: column;
  &_title {
    @include statistic-title();
  }

  .apply-btn {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-right: 30px;
    button {
      background: #3b61a5;
      border-radius: 12px;
      font-family: Arial;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff;
      padding-left: 70px;
      margin: 40px 30px 38px 0px;
      span {
        text-transform: lowercase;
        padding: 20px 70px 20px 0px;
      }
    }
  }

  .MuiButtonGroup-root {
    .MuiButton-root {
      border-right: 1px solid $white;

      font-family: Arial;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      background: #55bbbe;
      height: 48px;
    }
  }

  .users__filter--control {
    background: #e7ecf5;
    box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
      0px 1px 4px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
    border-radius: 10px;

    .BtnActive {
      background: #1a9696;
    }

    .title {
      display: flex;

      flex-direction: column;
      font-family: "Arial";
      .main__title {
        text-transform: uppercase;
        padding: 0;
        margin-top: 38px;
        margin-left: 30px;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
      }

      span {
        margin-top: 20px;
        align-items: center;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 30px;
      }
    }

    .box-row {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      padding: 40px 30px 38px 30px;

      button {
        height: 58px;
      }
    }
  }

  .data__filter--control {
    background-color: #e7ecf5;
    box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
      0px 1px 4px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    .BtnActive {
      background: #1a9696;
    }
    .apply-btn {
      height: 48px;
      button {
        background: #3b61a5;
      }
    }
    p {
      margin-top: 40px;
      margin-left: 30px;
      font-family: Arial;
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      color: #1a9696;
    }
    .form {
      width: 70%;
      .user__form-control {
        margin-left: 30px;
        margin-right: -2px;
      }
    }
    .title {
      display: flex;

      flex-direction: column;

      .main__title {
        text-transform: uppercase;
        padding: 0;
        margin-top: 38px;
        margin-left: 30px;
        font-family: "Arial";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
      }
      span {
        margin-top: 20px;
        align-items: center;
        font-family: Arial;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 30px;
      }
    }

    .MuiInputBase-root {
      background: #ffffff;
      width: 280px;
      .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        margin-left: 5px;
        height: 48px;
        padding: 0px 0px 0px 0px;
      }
    }
    .special__filters--form {
      width: 95%;
      display: flex;

      .css-1as232s-MuiStack-root {
        width: 100%;
      }
      span {
        color: #2f2c2c;
        padding-bottom: 8px;
        font-size: 14px;
      }
      .css-1as232s-MuiStack-root {
        padding: 0;
      }
      .user__form-control {
        margin-left: 30px;
        margin-right: -2px;
        flex-direction: column-reverse;
        align-items: flex-start;
        width: calc(50% - 10px);

        .MuiTextField-root,
        .MuiInputBase-root {
          width: 100%;
          height: 48px;
          background: #ffffff;
          .MuiInputAdornment-root {
            svg {
              color: #1a9696;
            }
          }

          .MuiInputBase-input {
            padding-left: 12px;
          }

          .MuiOutlinedInput-root {
            &.Mui-focused fieldset {
              border-width: 1px;
            }
          }
        }
      }
    }

    .box-row {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      padding: 40px 30px 38px 30px;

      button {
        height: 58px;
      }
    }
  }

  .scoring__system--control {
    background: #e7ecf5;
    box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
      0px 1px 4px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    .BtnActive {
      background: #1a9696;
    }
    .form {
      width: 70%;
      .user__form-control {
        margin-left: 30px;
        margin-right: -2px;
      }
    }
    .title {
      display: flex;

      flex-direction: column;
      font-family: "Arial";
      .main__title {
        text-transform: uppercase;
        padding: 0;
        margin-top: 38px;
        margin-left: 30px;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
      }
      span {
        margin-top: 20px;
        align-items: center;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 30px;
      }
    }

    .box-row {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      padding: 40px 30px 38px 30px;

      button {
        height: 58px;
      }
    }
  }

  .MuiInputBase-root {
    background: #ffffff;
    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
      margin-left: 5px;
      height: 48px;
      padding: 0px 0px 0px 0px;
    }
  }
}

.statistic-total-values {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  margin-top: 40px;

  .item1,
  .item2,
  .item3 {
    .image-wrapp {
      img {
        color: #2ad4ac21;
      }
      background-color: #2ad4ac21;
    }
    .total-value {
      color: #2ad4ac;
    }
  }

  .item4,
  .item5,
  .item6 {
    .image-wrapp {
      background-color: #e2e8f2;
    }
    .total-value {
      color: #3b61a5;
    }
  }

  .item7,
  .item8,
  .item9 {
    .image-wrapp {
      background-color: #fdf2db;
    }
    .total-value {
      color: #f3a50c;
    }
  }

  &_item {
    display: grid;
    min-height: 180px;
    grid-template-columns: 26% 70%;
    column-gap: 30px;
    background: #ffffff;
    box-shadow: 2px 4px 7px #d4dcec;
    border-radius: 20px;
    margin-top: 25px;

    .image-wrapp {
      img {
        height: 42.373416900634766px;
        width: 42.369693756103516px;
        left: 6.81640625px;
        top: 7.56591796875px;
        border-radius: 0px;
      }
      display: flex;
      height: 106px;
      width: 106px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 30px 25%;
      background: #d6f1ff;
      grid-column: 1 / 2;
      grid-row: span 2;
    }

    .title {
      display: flex;
      align-items: flex-end;
      color: #2f2c2c;
      padding-top: 8px;
      font-weight: 400;
      font-size: 18px;
      font-style: normal;
      font-family: "Arial";
      line-height: 25px;
      margin-left: 20px;
      width: 80%;
    }

    .total-value {
      display: flex;
      align-items: flex-start;
      font-size: 32px;
      font-weight: 700;
      font-family: "Arial";
      color: $gray-dark;
      margin-left: 20px;
    }

    .empty {
      grid-column: 1 / 2;
      grid-row: span 2;
      column-width: 10%;
    }
  }

  .without-image {
    grid-template-columns: 10% 80%;
    column-gap: 0;
    height: 98px;
  }
}

.personal-scoring {
  display: flex;
  box-shadow: 0px 1px 4px #e5e9f2;
  border-radius: 20px;
  background-color: $white;
  padding: 30px 50px 50px;
  margin-top: 50px;

  .border-box {
    box-sizing: border-box;
    padding-top: 32px;
    width: 90%;

    background: #ffffff;
    border: 3px solid #e7ecf5;
    border-radius: 16px;

    /* Inside auto layout */
    span {
      padding-left: 30px;
    }
    canvas {
      padding: 20px 30px 30px 30px;
    }
  }

  &_chart {
    display: flex;
    flex-direction: column;
    width: 50%;

    .chart-title {
      font-family: "Arial";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      margin-bottom: 20px;
      span {
        width: 183px;
        height: 21px;
        font-family: "Arial";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #2f2c2c;
      }
    }

    .chart-wrapp {
      span {
        position: relative;
        top: 32px;
        left: 30px;
      }
      .Bar {
        margin-top: 50px;
      }
    }

    @media (max-width: 1680px) {
      .chart-wrapp {
        flex-direction: column;

        .value-wrapp {
          flex-direction: row;
          margin-top: 20px;
          padding: 0;

          span {
            font-size: 36px;
            padding-right: 8px;
          }
        }
      }
    }
  }

  &_your-score {
    display: flex;
    flex-direction: column;
    width: 50%;

    .title {
      font-size: 18px;
      font-weight: 700;
      font-family: "Arial";
      padding-bottom: 30px;
      text-transform: uppercase;
    }

    .activeness,
    .productivity,
    .successfulness {
      display: flex;
      flex-direction: column;
      font-family: $fontSecond;

      .subtitle {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #2f2c2c;
      }

      .created-value-wrapp {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #94a1a2;
        span {
          position: relative;
          font-weight: 700;
          font-size: 18px;
          line-height: 21px;
          color: $gray-dark2;
          padding-right: 30px;

          &::after {
            content: "";
            position: absolute;
            border: 1px solid rgba(165, 178, 179, 0.4);
            transform: rotate(90deg);
            width: 32px;
            right: -2px;
            top: 10px;
          }
        }
      }

      &:not(:last-child) {
        padding-bottom: 68px;
      }

      .MuiLinearProgress-root {
        height: 20px;
        border-radius: 30px;
        margin: 20px 0;
        background: #eff3f9;

        .MuiLinearProgress-bar {
          border-radius: 50px;
        }
      }
    }

    .activeness {
      .MuiLinearProgress-bar {
        background: #da5674;
      }
    }

    .productivity {
      .MuiLinearProgress-bar {
        background: #f3a50c;
      }
    }

    .successfulness {
      .MuiLinearProgress-bar {
        border-radius: 20px;
        background: $green-light;
      }
    }
  }
}

.correlation-scoring-and-dynamics-block {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 50px;
  row-gap: 30px;
  margin-top: 50px;

  .correlation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $gray-low;
    border: 1px solid $gray-light;
    border-radius: 20px;
    padding: 15px 35px;
    font-family: $fontSecond;
    grid-column: span 2;
    grid-row: span 2;

    &_title {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      padding-bottom: 20px;
    }

    .tech-proposals-values {
      display: flex;
      padding-bottom: 50px;

      &_item {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        color: $gray-dark2;

        &:first-child {
          padding-right: 128px;
        }

        span {
          font-weight: 700;
          font-size: 26px;
          line-height: 30px;
          color: $gray-dark;
          margin-top: 16px;
        }
      }
    }
  }

  .distributor-scoring {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $gray-low;
    border: 1px solid $gray-light;
    border-radius: 20px;

    .MuiTypography-root {
      display: none;
    }

    &_select-wrapp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 70%;
      padding: 0 0 40px 22px;

      .MuiFormControlLabel-root {
        width: 70%;
      }

      .MuiOutlinedInput-root {
        height: 30px;
      }
    }
  }

  .dynamics {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $gray-low;
    border: 1px solid $gray-light;
    border-radius: 20px;
  }

  .distributor-scoring,
  .dynamics {
    &_header {
      display: flex;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: $gray-dark2;
      padding: 25px 0 12px 22px;

      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 26px;
        color: $gray-dark;
        width: 58%;
      }
    }

    &_values-wrapp {
      display: flex;
      flex-direction: column;
      line-height: 28px;
    }

    .up,
    .down {
      font-weight: 400;
      font-size: 14px;
    }

    .up {
      position: relative;
      color: $green-light;

      &::after {
        content: "";
        position: absolute;
        background-image: url(../../assets/images/up.svg);
        width: 18px;
        height: 10px;
        top: 3px;
        left: 60px;
      }
    }

    .down {
      position: relative;
      color: #f35757;

      &::after {
        content: "";
        position: absolute;
        background-image: url(../../assets/images/down.svg);
        width: 18px;
        height: 13px;
        top: 5px;
        left: 60px;
      }
    }
  }

  img {
    width: 100%;
    margin-top: 1px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

.technical-statistic {
  &_title {
    @include statistic-title();
  }

  .pipe-info {
    display: flex;
    justify-content: space-between;

    .type-title,
    .usage-title {
      @include secondary-title();
      margin-bottom: 30px;
    }

    &_type {
      width: 25%;

      .bar-chart-wrapp {
        background-color: $white;
        border-radius: 13px;
        padding: 50px 10px 20px 10px;
        margin-top: 30px;
        height: 380px;
      }
    }

    &_usage {
      width: 70%;

      .doughnut-legend {
        display: flex;
        margin-top: 30px;
        column-gap: 30px;
        font-size: 14px;
        line-height: 16px;

        div {
          display: flex;
          align-items: center;
        }

        span {
          display: block;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          margin-right: 5px;
        }
      }

      .doughnut-charts {
        display: flex;
        column-gap: 50px;
        margin-top: 30px;

        &_item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          background-color: $white;
          border-radius: 13px;
          width: 50%;
          height: 380px;

          .title {
            font-size: 20px;
            color: #4f4f4f;
            padding: 34px 0 50px 0;
          }

          .percents-wrapp {
            display: flex;
            justify-content: space-between;
            padding: 25px 0;
            width: 50%;

            span {
              font-size: 20px;
              line-height: 23px;
            }
          }

          @media (max-width: 1600px) {
            .percents-wrapp {
              width: 65%;
            }
          }
        }
      }

      .action-group {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .bar-legend {
    display: flex;
    margin-top: 30px;
    column-gap: 20px;
    line-height: 16px;

    div {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #4f4f4f;
    }
  }

  .approved {
    display: flex;
    width: 16px;
    height: 16px;
    background: #e786d7;
    border: 1px solid #e786d7;
    border-radius: 5px;
    margin-right: 5px;
  }

  .sent {
    display: flex;
    width: 16px;
    height: 16px;
    background: #7f7fd5;
    border: 1px solid #7f7fd5;
    border-radius: 5px;
    margin-right: 5px;
  }

  .btn-wrapp {
    margin-left: 40px;
    display: flex;

    .green {
      background: #2ad4ac;
    }

    .red {
      background: #f65761;
    }

    .orange {
      background: #f3a50c;
    }

    .light-blue {
      background: #5699dd;
    }

    .purple {
      background: #7b61ff;
    }

    .blue {
      background: #226cab;
    }

    .MuiButton-root {
      border: 1px solid #ebebeb;
      border-radius: 8px;
      padding: 12px 1.5vw;
      color: $white;
      height: 40px;
      text-transform: none;
      font-weight: 400;
      font-size: 14px;
      position: relative;
      line-height: 16px;

      .close-svg {
        position: absolute;
        top: 11px;
        right: 10px;
      }

      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    @media (max-width: 1700px) {
      .MuiButton-root {
        padding: 12px 1.2vw;
      }
    }

    @media (max-width: 1580px) {
      .MuiButton-root {
        padding: 12px 1vw;
      }
    }

    .no-active {
      background: #e5e5e5;
    }
  }

  @media (max-width: 1600px) {
    .btn-wrapp {
      margin-left: 10px;
    }
  }

  .pression-with-temp {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    .pression,
    .temperature {
      width: 45%;

      &_title {
        @include secondary-title();
        padding-bottom: 40px;
      }

      &_action-group {
        display: flex;
        padding-bottom: 25px;

        .filter-input {
          width: 50%;
        }
      }
    }
  }

  .design-and-defect-type {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    .design-consideration,
    .type-of-defect {
      &_title {
        @include secondary-title();
        padding-bottom: 15px;
      }

      &_buttons {
        padding-left: 20px;
      }

      .bar-chart-wrapp {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        background: #fdfdff;
        border: 1px solid #ebebeb;
        border-radius: 13px;
        height: 400px;
        width: 370px;
        margin-top: 45px;
        padding-bottom: 15px;

        &_title {
          font-weight: 400;
          font-size: 20px;
          line-height: 23px;
          color: #4f4f4f;
          display: flex;
          align-items: center;
          text-align: center;
          width: 60%;
        }
      }

      &_action-group {
        display: flex;
        position: relative;

        .type-of-defect-legend {
          position: absolute;
          left: 4px;
          top: 25px;
        }
      }

      @media (max-width: 1700px) {
        &_action-group {
          display: block;

          .btn-wrapp {
            margin: 0;
            position: absolute;
            left: 0;
            top: 50px;
          }

          .type-of-defect-legend {
            top: 35px;
          }
        }
      }
    }

    @media (max-width: 1700px) {
      .design-consideration,
      .type-of-defect {
        &_buttons {
          padding-left: 25px;
          padding-right: 35px;
        }

        .bar-chart-wrapp {
          margin-top: 65px;
        }
      }
    }
  }

  .defect-type-with-metall-loss,
  .defect-localisation-with-sealing-procedure {
    display: flex;
    column-gap: 40px;
    margin-top: 40px;

    .defect-type,
    .metall-loss,
    .defect-localisation,
    .sealing-procedure {
      width: 50%;

      &_title {
        @include secondary-title();
        padding-bottom: 20px;
      }

      &_action-group {
        display: flex;
        padding-bottom: 20px;
      }
    }

    .defect-localisation,
    .sealing-procedure {
      &_action-group {
        display: flex;
        flex-direction: column;

        .filter-input {
          width: 45%;
        }

        .btn-wrapp {
          margin: 0;
          padding-top: 25px;
        }
      }
    }

    .sealing-procedure {
      &_btn-wrapp {
        .MuiButton-root {
          padding: 12px 12px;
        }
      }
    }
  }

  .charts-wrapp {
    display: flex;
    column-gap: 15px;

    &_item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background: #fdfdff;
      border: 1px solid #ebebeb;
      border-radius: 13px;
      width: 50%;

      .chart-title {
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 20px;
        line-height: 23px;
        color: #4f4f4f;
        padding: 30px 35px;
      }

      .percents-wrapp {
        display: flex;
        justify-content: space-between;
        width: 58%;
        padding-bottom: 15px;

        span {
          font-size: 20px;
          line-height: 23px;
        }
      }

      @media (max-width: 1700px) {
        .percents-wrapp {
          width: 65%;
        }
      }
    }
  }

  .design-lifetime {
    margin-top: 40px;

    &_title {
      @include secondary-title();
      padding-bottom: 20px;
    }

    .filter-input {
      width: 20%;
    }

    &_btn-wrapp {
      .MuiButton-root {
        border: 1px solid #ebebeb;
        border-radius: 8px;
        padding: 12px 30px;
        color: #ffffff;
        height: 40px;
        text-transform: none;
        font-weight: 400;
        font-size: 14px;
        position: relative;
        line-height: 16px;
        padding-left: 15px;
        margin: 20px 10px 20px 0;
      }

      .close-svg {
        position: absolute;
        top: 12px;
        right: 10px;
      }
    }
  }

  .sales-seasonnality-chart {
    margin-top: 40px;

    &_title {
      @include secondary-title();
      padding-bottom: 30px;
    }

    .filter-input {
      width: 20%;
    }

    .line-chart-wrapp {
      width: 50%;
    }

    &_btn-wrapp {
      .MuiButton-root {
        border: 1px solid #ebebeb;
        border-radius: 8px;
        padding: 12px 30px;
        color: #ffffff;
        height: 40px;
        text-transform: none;
        font-weight: 400;
        font-size: 14px;
        margin: 20px 10px 20px 0;
      }
    }

    .line-chart-legend {
      display: flex;
      align-items: center;
      color: #3b4652;
      font-size: 14px;
      margin-bottom: 18px;

      .circle {
        display: block;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        margin-right: 5px;

        &:not(:first-child) {
          margin-left: 10px;
        }
      }

      .straight {
        background: #2ad4ac;
      }

      .bend {
        background: #da5674;
      }

      .tee {
        background: #f3a50c;
      }
    }
  }
}
