@import "~styles/variables";

.modal-history {
  [aria-labelledby="customized-history"] {
    width: 1015px;
    max-width: 1015px;
    border-radius: 8px;
  }

  #customized-history {
    background: $header;
    border-radius: 8px 8px 0px 0px;

    font-family: $fontPrimary;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;

    color: $brandPrimaryDark;

    .MuiSvgIcon-root {
      fill: #000;
    }

    .MuiIconButton-root {
      top: 24px;
      right: 35px;
    }
  }

  .MuiDialogTitle-root {
    padding-left: 45px;
  }

  &_body {
    height: 540px;
    padding: 60px 75px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .MuiPaper-root {
      box-shadow: none;

      .MuiTableCell-head {
        font-family: $fontSecond;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        text-align: center;
        text-transform: uppercase;

        color: #3b61a5;
      }

      .MuiTableCell-body {
        font-family: $fontSecond;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        border: 1px solid #dadedf !important;

        color: #2f2c2c;
      }
    }

    &_wrap-btns {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    &_error-text {
      background: rgba(255, 141, 148, 0.16);
      border: 1px solid #f65761;
      box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
      border-radius: 5px;

      font-family: $fontSecond;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 28px;

      display: flex;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      padding: 8px 70px;

      color: $black;
    }
  }
}
