@import "~styles/variables";

.header {
  height: 92px;
  box-shadow: 0px 4px 10px $shadow;
  z-index: 2;

  & .container {
    padding: 18px 72px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .avatar-user {
      width: 30px;
    }

    img[alt=logo] {
      width: 145px;
    }

    .btn-wrapp {
      button {
        margin-left: 30px;
      }
    }

    div:nth-child(2) {
      flex: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 50px;
      font-family: "Arial";
      font-size: 14px;
      line-height: 16px;
      text-transform: capitalize;

      color: $brandSecondaryDark;

      span:nth-child(1) {
        margin-right: 37px;
        color: #2F2C2C;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #1A9696;
          top: -2px;
          right: -5px;
        }
      }
    }

    div:nth-child(3) {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        min-width: initial;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
