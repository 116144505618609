@import "~styles/variables";
@import "~styles/mixins";

.modal-add-composit {
  [aria-labelledby="customized-add-composit"] {
    width: 1115px;
    max-width: 1115px;
    border-radius: 8px;
    // box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  }

  #customized-add-composit {
    background: $header;
    border-radius: 8px 8px 0px 0px;

    font-family: $fontPrimary;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;

    color: $brandPrimaryDark;

    @include modal-title();

    .MuiSvgIcon-root {
      fill: #000;
    }

    .MuiIconButton-root {
      top: 20px;
      right: 35px;
    }
  }

  .MuiDialogTitle-root {
    padding: 0 45px;
  }

  &_body {
    //   height: 1120px;
    padding: 22px 60px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_form {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;

      &_control-label {
        margin-left: 0px;
        margin-right: 0px;
        flex-direction: column-reverse;
        align-items: flex-start;
        width: calc(33.3% - 30px);

        .MuiFormControlLabel-label {
          font-family: $fontSecond;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;

          color: $brandSecondaryDark;

          &::after {
            content: "*";
            color: red;
          }
        }

        .MuiTextField-root,
        .MuiInputBase-root {
          width: 100%;
          height: 48px;

          .MuiInputBase-input {
            padding: 12.5px 14px;
          }

          .MuiOutlinedInput-root {
            &.Mui-focused fieldset {
              border-width: 1px;
            }
          }
        }
      }

      &_control-label-small,
      &_control-label-small-two,
      &_control-label-small-three {
        margin-left: 0px;
        margin-right: 0px;
        display: flex;
        justify-content: space-between;

        &_wrap-field {
          flex: none;
          flex-basis: 29%;
        }

        &_units {
          font-family: $fontSecond;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #94a1a2;
          margin-left: 10px;
        }

        .MuiFormControlLabel-label {
          font-family: $fontSecond;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #94a1a2;
        }

        .MuiTextField-root,
        .MuiInputBase-root {
          width: 75px;
          height: 25px;

          .MuiInputBase-input {
            padding: 6px 7px;
          }
        }
      }

      &_control-label-small-two {
        &_wrap-field {
          flex-basis: 68%;
          display: flex;
          column-gap: 10px;
        }

        &_prefix {
          font-family: $fontSecond;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #f65761;
          margin-right: 9px;
        }
      }

      &_control-label-small-three {
        &_wrap-field {
          flex-basis: 72%;
          display: flex;
          column-gap: 40px;
        }

        &_label {
          font-family: $fontSecond;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #94a1a2;
          margin-right: 15px;
        }
      }

      &_checkbox {
        .Mui-checked {
          color: $brandPrimaryColor;
        }

        .MuiFormControlLabel-label {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;

          color: #505050;
        }
      }

      &_wrap-cols {
        width: 100%;
        display: flex;
        column-gap: 125px;

        &_col1,
        &_col2 {
          display: flex;
          flex-direction: column;
          row-gap: 15px;
          width: calc((100% - 125px) / 2);
        }
      }

      &_wrap-btns {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        column-gap: 20px;
      }

      &_success-text {
        background: #e9fffa;
        border: 1px solid $green-light;
        box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
        border-radius: 5px;

        font-family: $fontSecond;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 28px;

        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        padding: 8px 70px;

        color: $black;
      }

      &_error-text {
        background: rgba(255, 141, 148, 0.16);
        border: 1px solid #f65761;
        box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
        border-radius: 5px;

        font-family: $fontSecond;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 28px;

        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        padding: 8px 70px;

        color: $black;
      }
    }
  }
}
