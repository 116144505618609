@import "~styles/variables";

.general-information {
  &_form {
    &_input {
      font-family: $fontSecond;
      height: 91%;
      border: 1px solid #dadedf;
      border-radius: 18px;
      padding: 20px 30px;

      color: $black;
    }

    &_wrap-btns {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      column-gap: 20px;
      margin-top: 60px;
    }

    &_success-text {
      width: 50%;
      background: #e9fffa;
      border: 1px solid #2ad4ac;
      box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
      border-radius: 5px;

      font-family: $fontSecond;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 28px;

      display: flex;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      padding: 8px 70px;

      color: $black;
    }

    &_error-text {
      width: 50%;
      background: rgba(255, 141, 148, 0.16);
      border: 1px solid #f65761;
      box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
      border-radius: 5px;

      font-family: $fontSecond;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 28px;

      display: flex;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      padding: 8px 70px;

      color: $black;
    }
  }
}
