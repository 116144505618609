@import "~styles/variables";

.modal-add-bend {
  [aria-labelledby="customized-add-bend"] {
    width: 726px;
    max-width: 726px;
    border-radius: 8px;
    // box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  }

  #customized-add-bend {
    background: $header;
    border-radius: 8px 8px 0px 0px;

    font-family: $fontPrimary;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 56px;

    color: $brandPrimaryDark;

    .MuiIconButton-root {
      top: 24px;
    }
  }

  &_body {
    height: 320px;
    padding: 42px 60px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_form {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;

      &_control-label {
        margin-left: 0px;
        margin-right: 0px;
        flex-direction: column-reverse;
        align-items: flex-start;
        width: calc(50% - 15px);

        .MuiFormControlLabel-label {
          font-family: $fontSecond;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;

          color: $brandSecondaryDark;

          &::after {
            content: "*";
            color: red;
          }
        }

        .MuiTextField-root,
        .MuiInputBase-root {
          width: 100%;
          height: 48px;

          .MuiInputBase-input {
            padding: 12.5px 14px;
          }
        }
      }

      &_checkbox {
        .Mui-checked {
          color: $brandPrimaryColor;
        }

        .MuiFormControlLabel-label {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;

          color: #505050;
        }
      }

      &_wrap-btns {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        column-gap: 20px;
      }

      &_success-text {
        background: #e9fffa;
        border: 1px solid #2ad4ac;
        box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
        border-radius: 5px;

        font-family: $fontSecond;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 28px;

        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        padding: 8px 70px;

        color: $black;
      }

      &_error-text {
        background: rgba(255, 141, 148, 0.16);
        border: 1px solid #f65761;
        box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
        border-radius: 5px;

        font-family: $fontSecond;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 28px;

        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        padding: 8px 70px;

        color: $black;
      }
    }
  }
}
