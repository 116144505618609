@import "~styles/variables";
@import "~styles/mixins";

.marketing-statistic {
  min-width: 900px;

  &_title {
    @include statistic-title();
  }

  &_filter-control {
    background: #e7ecf5;
    box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
      0px 1px 4px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
    border-radius: 10px;

    .MuiButtonGroup-root {
      .MuiButton-root {
        border-right: 1px solid $white;

        font-family: Arial;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        text-transform: none;
        background: #55bbbe;
        height: 48px;
      }

      .btn-active {
        background: #1a9696;
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      font-family: "Arial";
      width: 35%;

      .main-title {
        text-transform: uppercase;
        padding: 0;
        margin-top: 38px;
        margin-left: 30px;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
      }

      span {
        margin-top: 20px;
        align-items: center;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 30px;
      }
    }

    .apply-btn {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      button {
        background: #3b61a5;
        border-radius: 12px;
        font-family: Arial;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        color: #ffffff;
        padding-left: 70px;
        margin: 40px 30px 38px 30px;

        span {
          text-transform: lowercase;
          padding: 20px 70px 20px 0px;
        }
      }
    }

    .box-row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 40px 30px 38px 30px;

      button {
        height: 58px;
      }
    }
  }

  &_form {
    margin-bottom: 60px;

    &_control {
      flex-direction: column-reverse;
      max-width: 100%;
      width: 100%;

      margin: 0;
    }
  }

  .composites-with-type {
    display: flex;
    flex-direction: column;
    column-gap: 70px;
    font-family: $fontSecond;
    border-radius: 20px;
    background-color: #ffffff;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-top: 40px;

    .composites {
      width: 50%;
      min-width: 600px;
      margin: auto;

      &_title {
        display: flex;
        justify-content: center;
        font-family: $fontSecond;
        font-weight: 700;
        font-size: 24px;
        line-height: 23px;
        color: #4f4f4f;
        text-transform: uppercase;
      }

      .text-empty {
        text-align: center;
      }
    }
  }
}
