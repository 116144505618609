/*variables.scss*/
//Fonts
$fontPrimary: "Roboto";
$fontSecond: "Arial";

$backgroundColorMainMenu: #002d72;
$heightHeaderGuest: 175px;

//colors
$brandSecondaryGreen: #55bbbe;
$darkGreen: #1A9696;
$shadow: #d4dcec;
$substrate: #e7ecf5;
$buttonsAndStroke: #3b61a5;
$header: #c7e7e8;
$brandSecondaryDark: #94a1a2;
$brandPrimaryDark: #000000;
$brandPrimaryColor: #a80d10;
$error: #f65761;

$imageBtnPrimary: linear-gradient(42.28deg, #f00000 27.18%, #f7b733 90.15%);
$colorBtnPrimary: #f6962a;
$colorBtnPrimaryPressed: #f7b733;
$primaryFontColor: #140f4f;
$secondaryFontColor: #471b51;
$thirdFontColor: #403347;
$grayFontColor: #918e93;

$white: #ffffff;
$white2: #e5e5e5;
$orange: #eb4335;
$red: #d01317;

$red-dark2: #bd0034;
$red-light: #dd4d50;
$red-dark-rgba: rgba(168, 12, 16, 0.5);
$black: #000000;
$black2: rgba(0, 0, 0, 0.87);
$black-shadow: rgba(0, 0, 0, 0.13);
$gray: rgba(0, 0, 0, 0.35);
$gray2: rgba(0, 0, 0, 0.38);
$gray-middle: rgba(148, 161, 162, 0.35);

$gray-dark: #3B4652;
$gray-dark2: #788585;
$gray-low: #FDFDFF;
$gray-light: #EBEBEB;
$gray-border: #6a7676;
$gray-background: #eeeeee;
$green: #239758;
$green-light: #2AD4AC;

// vars for styled components
// :root {
//   --thirdFontColor: #403347;
// }
