@import "~styles/variables";

.btn-primary {
  min-width: 151px;
  height: 40px;
  background: #3b61a5;
  border-radius: 12px;

  font-family: $fontSecond;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: none;

  color: $white;

  svg {
    margin-right: 5px;
  }

  &:hover {
    border: 2px solid #3b61a5;
    background: $white;
    color: #3b61a5;
  }

  &:focus,
  &:active {
    background: #3b61a5;
    border: 4px solid #55bbbe;
    color: $white;
  }
}

.btn-outline {
  width: 151px;
  height: 40px;
  background: $white;
  border: 2px solid #3b61a5;
  border-radius: 12px;

  font-family: $fontSecond;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-transform: none;

  color: #3b61a5;

  &:hover {
    border: 3px solid #55bbbe;
    background: $white;
  }

  &:focus,
  &:active {
    background: #e7ecf5;
    border: 4px solid #55bbbe;
  }
}

.btn-link {
  font-family: $fontPrimary;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  text-transform: none;
  height: fit-content;
  margin-left: auto;
  margin-top: auto;

  color: #1a9696;

  svg {
    margin-right: 5px;
  }
}

.btn-accept {
  width: 85px;
  height: 30px;
  font-family: $fontPrimary;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: none;

  color: #ffffff;

  background: $green-light;
  border-radius: 30px;
}

.btn-reject {
  width: 73px;
  height: 30px;
  font-family: $fontPrimary;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: none;

  color: #ffffff;

  background: #f65761;
  border-radius: 30px;
}

.btn-modal {
  width: 82px;
  height: 30px;
  font-family: $fontSecond;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;

  background: #1a9696;
  border-radius: 7px;

  &:hover {
    background: #ffffff;
    border: 1px solid #1a9696;
    color: #1a9696;
  }

  &:focus,
  &:active {
    background: #1a9696;
    border: 2px solid #55bbbe;
    color: #ffffff;
  }
}

.btn-modal-outline {
  width: 82px;
  height: 30px;
  font-family: $fontSecond;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #1a9696;

  background: #ffffff;
  border: 1px solid #1a9696;
  border-radius: 7px;

  &:hover {
    border: 1px solid #55bbbe;
    color: #55bbbe;
  }

  &:focus,
  &:active {
    border: 2px solid #55bbbe;
    color: #1a9696;
  }
}

.btn-popup {
  width: 82px;
  height: 30px;
  background: $brandPrimaryColor;
  border-radius: 7px;

  font-family: $fontSecond;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: $white;

  &:hover {
    border: 2px solid $brandPrimaryColor;
    background: $white;
    color: $brandPrimaryColor;
  }

  &:focus,
  &:active {
    background: $brandPrimaryColor;
    border: 2px solid $error;
    color: $white;
  }
}

.btn-popup-outline {
  width: 82px;
  height: 30px;
  background: $white;
  border: 2px solid #a80d10;
  border-radius: 7px;

  font-family: $fontSecond;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;

  color: $brandPrimaryColor;

  &:hover {
    border: 3px solid $error;
    color: $error;
  }

  &:focus,
  &:active {
    border: 4px solid $error;
    color: $brandPrimaryColor;
  }
}

.btn-primary-large,
.btn-outline-large {
  min-width: 170px;
  height: 40px;
}

.btn-primary-medium,
.btn-outline-medium {
  min-width: 105px;
  height: 40px;
}

.btn-primary-small,
.btn-outline-small {
  min-width: 103px;
  height: 32px;
  border-radius: 8px;
}
