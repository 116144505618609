@mixin trns($elem: all, $sec: 0.5s, $type: ease) {
  transition: $elem $sec $type;
}

@mixin before-svg {
  display: none;
  top: 40px;
  left: 10px;
  color: $gray-border;
  font-size: 10px;
  position: absolute;
  width: 0;
  height: 25px;
  background-color: #fff;
  z-index: 100;
}

@mixin before-hover-svg {
  width: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #94a1a2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

@mixin statistic-title {
  font-family: $fontSecond;
  font-size: 24px;
  font-weight: 700;
  color: $gray-dark;
  text-transform: uppercase;
  padding-bottom: 35px;
}

@mixin secondary-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #4f4f4f;
}

@mixin modal-title {
  font-family: $fontPrimary;
  font-weight: 700;
  font-size: 18px;
  color: #2f2c2c;
  font-style: normal;
  line-height: 80px;
}
