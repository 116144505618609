@import "~styles/variables";

.doughnut-chart-marketing {
  &_title {
    display: flex;
    justify-content: center;
    font-family: "Arial";
    font-weight: 700;
    font-size: 24px;
    line-height: 23px;
    color: #4f4f4f;
    text-transform: uppercase;
  }

  &_total {
    color: #1a9696;
    font-size: 20px;
    margin-top: 35px;
  }

  &_btn-wrap {
    margin: 35px 0;
    line-height: 50px;

    .MuiButton-root {
      font-family: $fontSecond;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      border-radius: 8px;
      padding: 12px 10px;
      color: #2f2c2c;
      height: 40px;

      margin-right: 10px;
    }
  }

  &_chart-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fdfdff;
    border-radius: 13px;
    border: 1px solid #ebebeb;
    margin-bottom: 30px;

    &_title {
      font-size: 24px;
      line-height: 26px;
      color: #4f4f4f;
      padding: 25px 0 65px 0;
    }

    &_percents-wrap {
      display: flex;
      justify-content: center;
      padding: 50px 0;
      flex-wrap: wrap;

      span {
        font-size: 20px;
        line-height: 23px;
        padding-right: 15px;
      }
    }
  }
}
