@import "~styles/variables";

.main-menu {
  width: 348px;

  .MuiMenuItem-root {
    min-height: 62px;

    &:hover {
      color: $brandSecondaryGreen;
    }
  }

  &_item {
    &:hover {
      color: $brandPrimaryColor;
    }
  }

  .active-sub-item {
    position: relative;

    &::before {
      position: absolute;
      content: "";
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: $darkGreen;
      top: 5px;
      left: -15px;
    }
  }
}
