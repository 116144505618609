@import "~styles/variables";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  appearance: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

body {
  font: 16px/1.4 Arial, sans-serif;

  .not-require {
    .MuiFormControlLabel-label {
      &::after {
        content: "";
      }
    }
  }

  .form-label {
    margin-bottom: 0;
  }

  .form-check {
    .form-check-input {
      width: 27px;
      height: 27px;
      margin-top: 0;
      margin-right: 15px;
    }

    .form-check-input:checked[type="radio"] {
      background-color: $white;
      border-color: $red-dark2;
      background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8.63112' cy='8.76662' r='8.32253' fill='%23BD0034'/%3E%3C/svg%3E");
      background-size: auto;
    }

    // .form-check-input:checked[type="checkbox"] {
    //   background-color: $red-dark2;
    //   border-color: $red-dark2;
    // }

    .form-check-input:checked[type="checkbox"] {
      background: $white;
      border-color: $white;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='13' viewBox='0 0 20 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.5878 0.518054C20.1666 1.17508 20.1316 2.20331 19.5095 2.81466L9.58912 12.5647C8.99851 13.1451 8.08372 13.1451 7.49311 12.5647L0.490465 5.68231C-0.131574 5.07095 -0.166629 4.04273 0.412166 3.3857C0.990961 2.72867 1.96443 2.69164 2.58647 3.303L8.54111 9.15535L17.4135 0.435351C18.0356 -0.176002 19.009 -0.138975 19.5878 0.518054Z' fill='%23A80D10'/%3E%3C/svg%3E%0A");
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .form-check-input:focus[type="checkbox"] {
      box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%);
    }
  }

  .row-gap-1 {
    row-gap: 1em;
  }

  .row-gap-2 {
    row-gap: 2em;
  }

  .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
    position: relative;
  }

  .MuiDataGrid-root {
    background-color: white;
  }

  .hover-btn .hover-on,
  .hover-btn:hover .hover-off {
    display: none;
  }
  
  .hover-btn:hover .hover-on {
    display: inline;
  }
  
  .hover-btn {
    cursor: pointer;
    border: none;
  }

  .MuiDataGrid-columnHeaders {
    background: #E7ECF5;
    border-radius: 0;

    .MuiDataGrid-iconSeparator {
      display: none;
    }
  }

  .MuiDataGrid-selectedRowCount {
    display: none;
  }

  .MuiDataGrid-columnHeaderTitle {
    color: #3B61A5;
    text-transform: uppercase;
    font-family: $fontPrimary;
    font-size: 12px;
  }
}

.MuiSwitch-root {
  .MuiSwitch-thumb {
    color: #fff;
  }
  
  .Mui-checked {
    .MuiSwitch-thumb {
      color: #1a9696;
    }
  }
  
  .MuiSwitch-track {
    background: rgba(60, 60, 67, 0.3);
  }
  
  .Mui-checked + .MuiSwitch-track {
    background-color: #1a9696;
    opacity: 0.5;
  }
}

.MuiFormControl-root {
  .Mui-focused {
    border: 3px solid rgba(54, 166, 201, 0.2);
    border-radius: 5px;
  
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #36A6C9;
      border-radius: 4px;
    }
  }
}

.scroll-fixed {
  overflow: hidden;
}

.box {
  width: 100%;
  min-width: 320px;
  position: relative;
}

.action-btn {
  display: flex; 
  align-items: center;
  padding: 5px;
}
