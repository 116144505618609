@import "~styles/variables";

.modal-info-user {
  position: absolute !important;
  top: 85px !important;
  right: 90px !important;
  left: auto !important;
  z-index: 99 !important;
  margin: 0 !important;
  width: 315px;

  .MuiDialog-container {
    height: auto;
  }

  [aria-labelledby="customized-dialog-title"] {
    margin: 0;
    width: 315px;
  }

  &_body {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_fio,
    &_fio-manager {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
    }

    &_fio {
      color: $red;
      margin-top: 18px;
    }

    &_email {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      // text-transform: lowercase;

      /* On Surface/High Emphasis */
      color: $black2;
    }

    &_subtitle {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;

      color: $black;
    }

    &_wrap-manager {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-top: 1px solid rgba(33, 33, 33, 0.08);
      width: 100%;
      padding: 21px;
    }

    &_position {
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0.15px;
    }

    &_fio-manager,
    &_position {
      color: $gray;
    }

    div:nth-child(1) {
      margin-top: 13px;
    }

    div:nth-child(4) {
      margin-top: 33px;
    }

    div:nth-child(5) {
      margin-top: 9px;
    }

    div:nth-child(6) {
      margin-top: 12px;
      margin-bottom: 11px;
    }
  }

  &_footer {
    justify-content: center !important;

    .btn-outline-secondary {
      color: $gray-dark;
      border: 1px solid $gray2;
      border-radius: 4px;

      &:hover {
        background: $gray2;
      }
    }
  }
}
