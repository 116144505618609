@import "~styles/variables";


.project-list {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $substrate;
    padding: 38px 30px;
    margin-bottom: 40px;
    border-radius: 10px;
  }

  &_title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
  }

  .MuiDataGrid-toolbarContainer,
  .MuiDataGrid-columnHeaders {
    background-color: $substrate;
    color: #3B61A5;
    text-transform: uppercase;
    font-size: 12px;
  }

  .MuiDataGrid-cell {
    &:first-child {
      display: flex;
      justify-content: center;
      padding: 0;
      background-color: #f0f4fa;
    }
  }

  .MuiDataGrid-columnHeader {
    &:first-child {
      padding-left: 0;
      color: $black;

      .MuiDataGrid-columnHeaderTitleContainer {
        display: flex;
        justify-content: center;
      }
    }
  }

  .MuiDataGrid-selectedRowCount {
    opacity: 0;
  }

  .number-wrapp {
    .MuiCheckbox-root {
      margin-bottom: 2px;
    }
  }

  .project-status {
    font-size: 12px;
    border-radius: 30px;
    padding: 7px 15px 4px;
  }
  
  .CALCULATED {
    color: #B843BA;
    background-color: #F7DDF8;
    border: 1px solid #B843BA;
  }
  
  .DONE {
    color: #1DA484;
    background-color: #C0EEE3;
    border: 1px solid #1DA484;
  }
  
  // .DRAFT {
  
  // };
  
  .GENERATED {
    background-color: rgba(243, 165, 12, 0.15);
    color: #F3A50C;
    border: 1px solid #F3A50F;
  }
  
  .REJECTED {
    background: rgba(246, 87, 97, 0.15);
    border: 1px solid #F65761;
    color: #F65761;
  }
  
  .VALIDATED {
    color: #295CCA;
    background-color: $substrate;
    border: 1px solid #295CCA;
  }
  
  .VALIDATION {
    background-color: #CCE6ED;
    color: #0C9CC9;
    border: 1px solid #0C9CC9;
  }
  
  .hover-btn .hover-on,
  .hover-btn:hover .hover-off {
    display: none;
  }
  
  .hover-btn:hover .hover-on {
    display: inline;
  }
  
  .hover-btn {
    cursor: pointer;
    border: none;
  }
  
  .action-btn-wrapp {
    display: flex;
    justify-content: center;
    width: 100%;
    column-gap: 10px;

    .action-btn {
      display: flex; 
      align-items: center;
      background-color: #f0f4fa;

      &:nth-child(2) {
        margin-top: 2px;
      }
    }
  }
}
