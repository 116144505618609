.data__filter--control,
.users__filter--control,
.scoring__system--control {
  .select-form-box,
  .special__filters--box {
    .user__form-control {
      align-items: start;
      flex-direction: column-reverse;

      span {
        margin-bottom: 8px;
        color: #2f2c2c;
        font-size: 14px;
        font-family: Arial;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
  .user__role {
    .btn-group {
      button {
        text-transform: capitalize;
      }
    }
  }
  .MuiTextField-root,
  .MuiInputBase-root {
    text-overflow: ellipsis;

    height: 48px;
    background: #ffffff;
    .MuiInputAdornment-root {
      svg {
        color: #1a9696;
      }
    }

    // .MuiInputBase-input {
    //   padding-left: 12px;
    // }

    .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-width: 1px;
      }
    }
  }
}
