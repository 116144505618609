@import "~styles/variables";

.content-management {
  &_title {
    font-family: $fontSecond;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;

    color: #2f2c2c;
  }

  &_body {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    &_card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: $white;
      background-image: url(../../assets/images/rea_background.svg);
      box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
      border-radius: 18px;
      padding: 30px;
      width: 450px;
      height: 200px;

      &_content,
      &_action {
        padding: 0;
      }

      &_content {
        font-family: $fontSecond;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;

        color: #2f2c2c;
      }

      &_action {
        justify-content: end;
      }
    }
  }

  .MuiDataGrid-cell {
    &:first-child {
      display: flex;
      justify-content: center;
      padding: 0;
      background-color: #f0f4fa;
    }
  }
}
