@import "~styles/variables";

.modal-login {
  background-image: url(../../assets/images/auth_background.png);

  .MuiBackdrop-root {
    background: none;
  }

  [aria-labelledby="customized-dialog-title"] {
    width: 748px;
    max-width: 748px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  }

  &_body {
    height: 485px;
    padding: 0px !important;
    display: flex;
    align-items: center;

    &_left {
      background-image: url(../../assets/images/auth_left.png);
      height: 100%;
      width: 47%;
    }

    &_right {
      height: 100%;
      width: 53%;
      padding: 0 34px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: $gray-background;
    }

    &_form,
    &_form-restore,
    &_form-change-password {
      width: 100%;
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .MuiFormLabel-root {
        font-family: $fontSecond;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 11px;

        color: $brandSecondaryDark;
      }

      .MuiTextField-root {
        width: 100%;

        .MuiOutlinedInput-input {
          background-color: $white;
          font-family: $fontSecond;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 24px;

          padding: 9px;
          color: $brandSecondaryDark;
        }
      }

      &_confirm-password-wrap {
        padding-top: 20px;
        padding-bottom: 36px;
      }

      &_code-wrap {
        padding-bottom: 20px;
      }

      .btn-next {
        min-width: 150px;
        height: 32px;
        width: fit-content;

        background: $brandPrimaryColor;
        border: 1px solid $brandPrimaryColor;
        color: $white;
        text-transform: none;
        filter: drop-shadow(0px 3px 10px rgba(168, 13, 16, 0.19));

        &:hover,
        &:active {
          border: 1px solid $brandPrimaryColor;
          background: $white;
          color: $brandPrimaryColor;
        }

        &:focus,
        &:active:focus {
          border: 1px solid $brandPrimaryColor;
          background: $white;
          color: $brandPrimaryColor;
          box-shadow: 0 0 0 0.25rem $red-dark-rgba;
        }

        &:disabled {
          background: $brandPrimaryColor;
          border: 1px solid $brandPrimaryColor;
          filter: drop-shadow(0px 3px 10px rgba(168, 13, 16, 0.19));
          cursor: not-allowed;
        }
      }

      .btn-prev {
        min-width: 150px;
        height: 32px;
        background: $white;
        color: $brandPrimaryColor;
        border: 1px solid $brandPrimaryColor;
        text-transform: none;
        filter: drop-shadow(0px 3px 10px rgba(168, 13, 16, 0.19));

        &:hover,
        &:active {
          border: 1px solid $brandPrimaryColor;
          background: $brandPrimaryColor;
          color: $white;
        }

        &:focus,
        &:active:focus {
          border: 1px solid $brandPrimaryColor;
          background: $brandPrimaryColor;
          color: $white;
          box-shadow: 0 0 0 0.25rem $red-dark-rgba;
        }

        &:disabled {
          background: $white;
          color: $brandPrimaryColor;
          border: 1px solid $brandPrimaryColor;
          filter: drop-shadow(0px 3px 10px rgba(168, 13, 16, 0.19));
          cursor: not-allowed;
        }
      }
    }

    &_form-restore,
    &_form-change-password {
      height: 100%;

      &_wrap-btns {
        display: flex;
        justify-content: space-between;
      }

      &_wrap {
        padding-bottom: 36px;
      }
    }

    .modal-login_wrap-restore {
      text-align: end;

      .modal-login_btn-restore {
        font-family: $fontSecond;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 11px;
        text-decoration: none;
        text-transform: none;

        color: $brandPrimaryColor;
      }
    }

    .modal-login_body_form-change-password_success-text {
      margin-top: 30px;

      background: #e9fffa;
      border: 1px solid $green-light;
      box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
      border-radius: 5px;

      font-family: $fontSecond;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 28px;

      display: flex;
      justify-content: center;

      color: $black;
    }
  }

  .modal-footer {
    border-top: none;
    padding: 0 40px;
    padding-bottom: 30px;
    justify-content: flex-end;

    .btn {
      min-height: 30px;
      min-width: 85px;
    }
  }

  &_question {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.25px;

    color: $black2;
  }
}
