@import "~styles/variables";

.users-management {
  &_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $substrate;
    font-family: $fontSecond;
    font-weight: 700;
    font-size: 24px;
    color: #2f2c2c;
    margin-bottom: 40px;

    box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1),
      0px 1px 4px rgba(69, 75, 87, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 35px 40px;
  }

  .MuiTabs-flexContainer {
    .MuiButtonBase-root {
      color: #1a9696;
    }
  }

  .MuiTabs-indicator {
    background-color: #1a9696;
  }

  .MuiDataGrid-cell {
    &:first-child {
      display: flex;
      justify-content: center;
    }
  }

  .action-btn {
    padding: 5px;
  }

  .btn-wrapp {
    display: flex;
    column-gap: 25px;
  }
}
