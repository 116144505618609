@import "~styles/variables";

.history {
  &_btn-group {
    width: 100%;

    &_btn {
      font-family: $fontSecond;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      height: 40px;
      background: #55bbbe;
      border: 1px solid #dadedf;
      border-radius: 8px;
      text-transform: none;
      color: $white;

      &:hover {
        background: #1a9696;
      }

      &:not(:last-of-type) {
        border-right: 3px solid #dadedf;
      }
    }

    &_btn-active {
      background: #1a9696;
    }

    .Mui-selected {
      color: $white;
      background-color: #1a9696;
    }

    .Mui-selected:hover {
      background-color: #1a9696;
    }
  }
}
