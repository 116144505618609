@import "~styles/variables";

.modal-confirm {
  [aria-labelledby="customized-confirm-dialog"] {
    width: 380px;
    max-width: 380px;
    border-radius: 10px;
  }

  &_body {
    display: flex;
    flex-direction: column;
    height: 243px;
    padding: 30px 40px !important;

    &_title {
      font-family: $fontSecond;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;

      color: $brandPrimaryColor;
    }

    &_question {
      font-family: $fontPrimary;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: 0.25px;
      margin-top: 20px;
      flex: 1;

      color: rgba(0, 0, 0, 0.87);
    }

    &_wrap-btns {
      display: flex;
      justify-content: flex-end;
      column-gap: 20px;
      width: 100%;
    }
  }
}
